import { getFormProps, useForm, getInputProps } from "@conform-to/react";
import { parseWithZod } from "@conform-to/zod";
import { Button } from "@nextui-org/react";
import {
  json,
  type LoaderFunctionArgs,
  redirect,
  type ActionFunctionArgs,
  type MetaFunction,
} from "@remix-run/cloudflare";
import { Form, useActionData, useNavigation } from "@remix-run/react";
import { authenticator } from "~/services/auth.server";
import { commitSession, getSession } from "~/services/session.server";
import { z } from "zod";

const schema = z.object({
  email: z.string({ required_error: "メールアドレスの入力は必須です" }),
  password: z.string({ required_error: "パスワードの入力は必須です" }),
});

export const meta: MetaFunction = () => {
  return [{ title: "Cytometa - ログイン" }];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  await authenticator.isAuthenticated(request, {
    successRedirect: "/upload",
  });
  return json({});
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const res = await authenticator.authenticate("user-login", request).catch(() => {});

  if (!res) {
    return json({
      result: {},
      error: "メールアドレスとパスワードの組み合わせが正しくありません",
    });
  }

  const session = await getSession(request.headers.get("cookie"));
  session.set(authenticator.sessionKey, res);
  const headers = new Headers({ "Set-Cookie": await commitSession(session) });

  return redirect("/upload", { headers });
};

const Login = () => {
  const lastResult = useActionData<typeof action>();
  const { state } = useNavigation();
  const [form, fields] = useForm({
    lastResult: lastResult?.result,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema });
    },
    shouldRevalidate: "onInput",
    shouldValidate: "onBlur",
  });

  return (
    <div className="bg-background flex justify-center items-center h-screen">
      <div className="hidden lg:flex items-center justify-center w-1/2 h-screen bg-primary">
        <p className="font-bold text-white text-7xl">Cytometa</p>
      </div>

      <div className="flex flex-col justify-center items-center bg-background sm:20 p-8 w-full lg:w-1/2">
        <h1 className="text-2xl font-semibold mb-4">Welcome !</h1>
        <Form method="POST" {...getFormProps(form)} className="w-full max-w-lg">
          {lastResult?.error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 my-4 rounded" role="alert">
              <span className="block sm:inline text-sm">{lastResult?.error}</span>
            </div>
          )}
          <div className="mb-4">
            <label htmlFor="username" className="block text-gray-600">
              Email
            </label>
            <input
              {...getInputProps(fields.email, { type: "email" })}
              className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
            />
            <span className="text-xs text-red-600">{fields.email.errors}</span>
          </div>

          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-600">
              Password
            </label>
            <input
              {...getInputProps(fields.password, { type: "password" })}
              className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
            />
            <span className="text-xs text-red-600">{fields.password.errors}</span>
          </div>
          <div className="w-full flex justify-center">
            <Button type="submit" isLoading={state !== "idle"} color="primary">
              Log in
            </Button>
          </div>
          <div className="w-full flex flex-col items-end mt-8 text-sm text-gray-400">
            <p>Forgot your password? Contact us.</p>
            <p>contact@quastellajapan.com</p>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default Login;
